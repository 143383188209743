export const theme = {
  main: {
    primary: '#ee7b21',
    white: '#ffffff',
    black: '#000000',
    iconColor: '#f1f1f1',
    pageColor: '#F3F4F5',
    green: '#00c37d',
    lightGreen: '#00c37d0c',
    loanColor: '#eb1d5b',
    errorColor: '#f44336;',
    textColor: '#0E202F',
    cardBorderColor: '#dbdee0',
    lightGray: '#f2f4f5',
    borderColor: '#eeeeee',
    zebraColor: '#f3f4f5',
    disabledBtn: '#777777',
    grayColor: '#868f97',
    linkColor: '#337ab7',
    menuBackgroundColor: '#3E4D59',
    linkHoverColor: '#23527c',
  },
  constants: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    zIndex: 99,
  },
  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXl: '500px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    laptopXl: '2000px',
    desktop: '2560px',
  },
  fonts: {
    primary: "'Open Sans', sans-serif",
    secondary: "'Montserrat', sans-serif",
  },
};