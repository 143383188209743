import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";
import dynamic from "next/dynamic";
import { eventLog, currentScreen } from "../firebase";
import { useRouter } from "next/router";

import { theme } from "config/theme";

const Layout = dynamic(() => import("components/Layout"));

import "../styles/globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";

function MyApp({ Component, pageProps }: AppProps) {
  const routers = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        currentScreen(url);
        eventLog("screen_view");
      };

      routers.events.on("routeChangeComplete", logEvent);
      logEvent(window.location.pathname);

      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  // useEffect(() => storePathValues, [routers.asPath]);

  // const storePathValues = () => {
  //   const storage = globalThis?.sessionStorage;
  //   if (!storage) return;
  //   // Set the previous path as the value of the current path.
  //   const prevPath: any = storage.getItem("currentPath");
  //   storage.setItem("prevPath", prevPath);
  //   // Set the current path value by looking at the browser's location object.
  //   storage.setItem("currentPath", globalThis.location.pathname);
  // }

  return (
    <ThemeProvider theme={theme}>
      <NextNProgress color={"rgb(255,155,0)"} />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ThemeProvider>
  );
}

export default MyApp;
