import { initializeApp, getApps } from "firebase/app"
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";

const firebaseConfig = {
  apiKey:process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain:process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId:process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket:process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId:process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId:process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

//@ts-ignore
if (!getApps().length) {
  initializeApp(firebaseConfig);
}
// const analytics = firebase.analytics;

export const eventLog = (eventName: string, options = {}) => {
  const analytics = getAnalytics();
  logEvent(analytics, eventName, options)
}

export const currentScreen = (eventName: string) => {
  const analytics = getAnalytics();
  setCurrentScreen(analytics, eventName)
}

// export const analytics = () => {
//   if (typeof window != undefined) {

//     //@ts-ignore
//     return getAnalytics();
//   } else {
//     return null
//   }
// }

// export default firebase;